import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";

import {
  useTheme,
  Button,
  TextField,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { FaCheck } from "react-icons/fa";

// Assets
import { ReactComponent as Background } from "../../assets/svg/background.svg";

// Api
import { Devices } from "../../api/constants";
import { useWindowSize } from "../../api/helper";
import { en } from "../../api/languages/en";
import "./style.scss";

const Screen7 = ({ setTargetName, isLoadingTutorialEnd, finishTutorial }) => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const [localTargetName, setLocalTargetName] = useState("");

  const handleTargetNameChange = (text) => {
    setLocalTargetName(text);
    syncParentTargetName(text);
  };

  const handlePress = async () => {
    await finishTutorial();
  };

  const syncParentTargetName = useCallback(
    debounce((value) => {
      setTargetName(value);
    }, 300),
    []
  );

  // Keyboard
  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handlePress();
    }
  };

  return !isLoadingTutorialEnd ? (
    <div className="tutorial-container">
      <div className="starting-goals-container">
        <div
          className="top-image"
          style={{
            height:
              currentDevice === Devices.PHONE ? 529 * (windowWidth / 375) : 529,
          }}
        >
          {currentDevice === Devices.PHONE ? (
            <Background
              width="100%"
              height={529 * (windowWidth / 375)}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          ) : (
            <Background
              width={375}
              height={529}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          )}

          <div className="addItemContainer">
            <div
              className="addItemInputContainer"
              style={{ backgroundColor: theme.palette.light.main }}
            >
              <label className="addItemText">
                What’s a target you’d like to achieve within a month?
              </label>
              <div className="addItemInputAndSendContainer">
                <TextField
                  variant="standard"
                  type="text"
                  placeholder={'eg - "Launch project Phoenix"'}
                  value={localTargetName}
                  autoComplete="off"
                  fullWidth
                  margin="dense"
                  autoFocus
                  required
                  onChange={(e) => handleTargetNameChange(e.target.value)}
                  onKeyDown={(e) => {
                    keyDownHandler(e);
                  }}
                  slotProps={{ htmlInput: { maxLength: 140 } }}
                  sx={{
                    input: { color: theme.palette.dark.main },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                    "& .MuiInput-underline:hover": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body">
        <label className="text" style={{ color: theme.palette.text.main }}>
          {en.TUTORIAL_7}
          {
            moment().startOf("day").format("YYYYMMDD") <
            moment().endOf("month").subtract(6, "days").format("YYYYMMDD")
              ? moment().format("MMMM")
              : moment().add(1, "month").format("MMMM") // If today is one of the last 7 days of the month, default to next month,
          }
        </label>
      </div>

      <div className="footer">
        <LinearProgress
          variant="determinate"
          color="tertiary"
          value={(7 * 100) / 7}
          style={{ width: 150 }}
        />
        {localTargetName !== "" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePress}
            sx={{ height: 50, width: 50 }}
          >
            <FaCheck size={24} />
          </Button>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="tutorial-container">
      <div className="starting-goals-container">
        <div
          className="top-image"
          style={{
            height:
              currentDevice === Devices.PHONE ? 529 * (windowWidth / 375) : 529,
          }}
        >
          {currentDevice === Devices.PHONE ? (
            <Background
              width="100%"
              height={529 * (windowWidth / 375)}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          ) : (
            <Background
              width={375}
              height={529}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          )}

          <div className="addItemContainer">
            <div
              className="addItemInputContainer"
              style={{ backgroundColor: theme.palette.light.main }}
            >
              <span className="addItemText">
                Hang tight, we are finishing up...
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <LinearProgress
          variant="determinate"
          color="tertiary"
          value={(7 * 100) / 7}
          style={{ width: 150 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handlePress}
          sx={{ height: 50, width: 50 }}
        >
          <CircularProgress color="light" size={15} />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Screen7);
