import { firestore, auth } from "../../api/firebase";

import { FETCH_MILESTONES, SET_LOADING_MILESTONES } from "../types";

import { captureException } from "@sentry/react";

var unsubscribers = [];
var fetchedMilestones = [];
var start = null;

const fetchMilestonesSuccess = (milestones) => ({
  type: FETCH_MILESTONES,
  payload: milestones,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_MILESTONES,
  payload: isLoading,
});

export const fetchMilestones = () => (dispatch) => {
  try {
    dispatch(setLoading(true));

    const currentUser = auth.currentUser;
    if (!currentUser) {
      dispatch(setLoading(false));
      captureException("User not authenticated when fetching milestones");
      return console.log("User not authenticated when fetching milestones");
    }

    const userUid = currentUser.uid;

    const milestonesSubscriber = firestore
      .collection("milestones")
      .doc(userUid)
      .collection("milestones")
      .orderBy("date", "desc")
      .limit(20)
      .onSnapshot(
        (snapshot) => {
          // save startAt snapshot
          if (snapshot.docs.length > 0) {
            start = snapshot.docs[snapshot.docs.length - 1];
          }

          fetchedMilestones = []; // Reset fetched milestones

          // append new milestones to array
          snapshot.docs.forEach((milestone) => {
            // filter out any duplicates (from modify/delete events)
            fetchedMilestones = fetchedMilestones.filter(
              (x) => x.id !== milestone.id
            );
            fetchedMilestones.push(milestone.data());
          });

          // Dispatch milestones to redux
          dispatch(fetchMilestonesSuccess(fetchedMilestones));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException(
            `Error fetching milestones onSnapshot: ${
              error.message || "Unknown error"
            }`,
            error
          );
          console.log("Error fetching milestones onSnapshot: " + error);
        }
      );
    unsubscribers.push(milestonesSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException(
      `Error fetching milestones: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching milestones: " + error);
  }
};

export const fetchMoreMilestones = () => (dispatch) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      captureException("User not authenticated when fetching more milestones");
      return console.log(
        "User not authenticated when fetching more milestones"
      );
    }

    const userUid = currentUser.uid;

    const milestonesSubscriber = firestore
      .collection("milestones")
      .doc(userUid)
      .collection("milestones")
      .orderBy("date", "desc")
      .startAfter(start)
      .limit(20)
      .get()
      .then((res) => {
        // previous starting boundary becomes new ending boundary
        if (res.docs.length > 0) {
          start = res.docs[res.docs.length - 1];
        }

        // append new milestones to array
        res.docs.forEach((milestone) => {
          // filter out any duplicates (from modify/delete events)
          fetchedMilestones = fetchedMilestones.filter(
            (x) => x.id !== milestone.id
          );
          fetchedMilestones.push(milestone.data());
        });

        // Displatch milestones to redux
        dispatch(fetchMilestonesSuccess(fetchedMilestones));
      })
      .catch((error) => {
        captureException(
          `Error fetching more milestones query: ${
            error.message || "Unknown error"
          }`,
          error
        );
        console.log("Error fetching more milestones query: " + error);
      });
    unsubscribers.push(milestonesSubscriber);
  } catch (error) {
    captureException(
      `Error fetching more milestones: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching milestones: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeMilestoneActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
