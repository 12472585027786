import { firestore, auth } from "../../api/firebase";

import { FETCH_INBOXES, SET_LOADING_INBOXES } from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];

const fetchInboxesSuccess = (inboxes) => ({
  type: FETCH_INBOXES,
  payload: inboxes,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_INBOXES,
  payload: isLoading,
});

export const fetchInboxes = () => (dispatch) => {
  try {
    dispatch(setLoading(true));

    const currentUser = auth.currentUser;
    if (!currentUser) {
      dispatch(setLoading(false));
      captureException("User not authenticated when fetching inboxes");
      return console.log("User not authenticated when fetching inboxes");
    }

    const userUid = currentUser.uid;

    // Get all not done inboxes
    const InboxesSubscriber = firestore
      .collection("inboxes")
      .doc(userUid)
      .collection("inboxes")
      .onSnapshot(
        (snapshot) => {
          const inboxes = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          dispatch(fetchInboxesSuccess(inboxes));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException(
            `Error fetching inboxes onSnapshot: ${
              error.message || "Unknown error"
            }`,
            error
          );
          console.log("Error fetching inboxes onSnapshot: " + error);
        }
      );
    unsubscribers.push(InboxesSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException(
      `Error fetching inboxes: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching inboxes: " + error);
  }
};

export const unsubscribeInboxActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
