import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import { FiCalendar } from "react-icons/fi";
import { FaSyncAlt } from "react-icons/fa";
import { useTheme, Divider, TextField, Tooltip } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

import {
  colors,
  lightTheme,
  tagColors,
  Devices,
  recurrencePatternEnum,
} from "../../../api/constants";
import "./style.scss";

const ViewGoalScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const goal = {
    ...location.state.goalToEdit,
    ...(location.state.goalToEdit.date && {
      date: moment(location.state.goalToEdit.date)
        .utc()
        .utcOffset(moment().utcOffset(), true),
    }), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
  };
  const date = moment(location.state.date); // date is received as "date" and converted to moment obj
  const tag = goal.tag;
  const name = goal.name;
  const description = goal.description;

  const btnBackClicked = () => {
    navigate("/", {
      state: {
        date: date.toDate(),
      },
    });
  };

  return (
    <div
      className="goal-preview-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice !== Devices.PHONE && "100vh",
        overflowY: currentDevice !== "PHONE" && "scroll",
      }}
    >
      <div className="goal-preview-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Goals"
          title="Goal Preview"
        />

        <Divider />

        <div className="body">
          <div className="edit-btns">
            <div className="edit-btns-first-section">
              <Tooltip title="Date">
                <button
                  className="btn-edit"
                  onClick={() =>
                    toast.error(
                      "You can't change the date of already achieved goals"
                    )
                  }
                >
                  <FiCalendar style={{ color: theme.palette.gray.dark }} />
                  <label
                    className="btn-edit-date"
                    style={{ color: theme.palette.gray.dark }}
                  >
                    {moment(date).format("MMM D")}
                  </label>
                </button>
              </Tooltip>
              <Divider
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />
              {goal.recurrencePattern && (
                <div className="repeat-icon-container">
                  <FaSyncAlt
                    color={
                      !goal.recurrencePattern ||
                      goal.recurrencePattern === recurrencePatternEnum.NONE
                        ? colors.darkGray
                        : lightTheme.colors.primary
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <Divider />

          <div className="title-container">
            {tag ? (
              <div
                className="goal-tag"
                style={{
                  backgroundColor: tag
                    ? tagColors[tag - 1].color
                    : "transparent",
                }}
              />
            ) : null}

            <TextField
              type="text"
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true,
                  style: {
                    display: "flex",
                    color: theme.palette.text.main,
                    fontSize: 20,
                    fontWeight: "bold",
                  },
                },
                htmlInput: {
                  maxLength: 140,
                },
              }}
              fullWidth
              autoComplete="off"
              multiline
              id="goalNameInput"
              placeholder="Add goal name..."
              value={name}
              className="goal-name"
              style={{
                paddingLeft: tag ? 10 : 0,
                color: theme.palette.text.main,
              }}
              disabled
            />
          </div>

          <Divider />

          <div className="goal-description-section">
            <label
              className="goal-description-title"
              style={{ color: theme.palette.text.main }}
            >
              Goal description
            </label>
            <TextField
              type="text"
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true,
                  style: { color: theme.palette.text.main },
                },
              }}
              sx={{ paddingTop: 1.5 }}
              fullWidth
              autoComplete="off"
              multiline
              minRows={10}
              id="goalDescriptionInput"
              placeholder="When, where and how will you get this done"
              value={description}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewGoalScreen);
