import { firestore, auth } from "../../api/firebase";

import { FETCH_COUNTERS, SET_LOADING_COUNTERS } from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];

const fetchCountersSuccess = (counters) => ({
  type: FETCH_COUNTERS,
  payload: counters,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_COUNTERS,
  payload: isLoading,
});

export const fetchCounters = () => (dispatch) => {
  try {
    dispatch(setLoading(true));

    const currentUser = auth.currentUser;
    if (!currentUser) {
      dispatch(setLoading(false));
      captureException("User not authenticated when fetching counters");
      return console.log("User not authenticated when fetching counters");
    }

    const userUid = currentUser.uid;

    const countersSubscriber = firestore
      .collection("counters")
      .doc(userUid)
      .onSnapshot(
        (snapshot) => {
          const counters = snapshot.data();
          dispatch(fetchCountersSuccess(counters));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException(
            `Error fetching counters onSnapshot: ${
              error.message || "Unknown error"
            }`,
            error
          );
          console.log("Error fetching counters onSnapshot: " + error);
        }
      );
    unsubscribers.push(countersSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException(
      `Error fetching counters: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching counters: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeCounterActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
