import { firestore, auth } from "../../api/firebase";
import moment from "moment";
import { captureException } from "@sentry/react";

import {
  FETCH_DAY,
  SET_LOADING_DAY,
  FETCH_TODAY,
  SET_LOADING_TODAY,
  FETCH_YESTERDAY,
  SET_LOADING_YESTERDAY,
} from "../types";

let unsubscribers = [];
let unsubscribersDay = [];

const fetchDaySuccess = (day) => ({
  type: FETCH_DAY,
  payload: day,
});

const setLoadingDay = (isLoadingDay) => ({
  type: SET_LOADING_DAY,
  payload: isLoadingDay,
});

const fetchTodaySuccess = (today) => ({
  type: FETCH_TODAY,
  payload: today,
});

const setLoadingToday = (isLoadingToday) => ({
  type: SET_LOADING_TODAY,
  payload: isLoadingToday,
});

const fetchYesterdaySuccess = (yesterday) => ({
  type: FETCH_YESTERDAY,
  payload: yesterday,
});

const setLoadingYesterday = (isLoadingYesterday) => ({
  type: SET_LOADING_YESTERDAY,
  payload: isLoadingYesterday,
});

// TODO consider if pagination is necessary, because Firebase suggests to avoid frequently churning listeners
// https://firebase.google.com/docs/firestore/best-practices#realtime_updates
// But this guy says that it won't cause technical issues (just not the cleanest approach)
// https://stackoverflow.com/a/66979317/8111691
export const fetchDay =
  (date = moment()) =>
  (dispatch) => {
    try {
      dispatch(setLoadingDay(true));

      const currentUser = auth.currentUser;
      if (!currentUser) {
        dispatch(setLoadingDay(false));
        captureException("User not authenticated when fetching day");
        return console.log("User not authenticated when fetching day");
      }

      const userUid = currentUser.uid;

      dispatch(fetchDaySuccess(null));

      const daySubscriber = firestore
        .collection("days")
        .doc(userUid)
        .collection("days")
        .doc(moment(date).format("YYYYMMDD"))
        .onSnapshot(
          (snapshot) => {
            if (snapshot.exists) {
              const data = snapshot.data();

              const day = {
                ...data,
                ...(data?.date && {
                  date: moment(
                    data.date && data.date.toDate && data.date.toDate()
                  )
                    .utc()
                    .utcOffset(moment().utcOffset(), true),
                }),
              };
              dispatch(fetchDaySuccess(day));
            }
            dispatch(setLoadingDay(false));
          },
          (error) => {
            dispatch(setLoadingDay(false));
            captureException(
              `Error fetching day onSnapshot: ${
                error.message || "Unknown error"
              }`,
              error
            );
            console.log("Error fetching day onSnapshot: " + error);
          }
        );
      unsubscribers.push(daySubscriber);
      unsubscribersDay.push(daySubscriber);
    } catch (error) {
      dispatch(setLoadingDay(false));
      captureException(
        `Error fetching day: ${error.message || "Unknown error"}`,
        error
      );
      console.log("Error fetching day: " + error);
    }
  };

export const fetchToday = () => (dispatch) => {
  try {
    dispatch(setLoadingToday(true));

    const currentUser = auth.currentUser;
    if (!currentUser) {
      dispatch(setLoadingToday(false));
      captureException("User not authenticated when fetching today");
      return console.log("User not authenticated when fetching today");
    }

    const userUid = currentUser.uid;

    dispatch(fetchTodaySuccess(null));

    const todaySubscriber = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(moment().format("YYYYMMDD"))
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();

            const today = {
              ...data,
              ...(data?.date && {
                date: moment(
                  data.date && data.date.toDate && data.date.toDate()
                )
                  .utc()
                  .utcOffset(moment().utcOffset(), true),
              }),
            };
            dispatch(fetchTodaySuccess(today));
          }
          dispatch(setLoadingToday(false));
        },
        (error) => {
          dispatch(setLoadingToday(false));
          captureException(
            `Error fetching today onSnapshot: ${
              error.message || "Unknown error"
            }`,
            error
          );
          console.log("Error fetching today onSnapshot: " + error);
        }
      );
    unsubscribers.push(todaySubscriber);
  } catch (error) {
    dispatch(setLoadingToday(false));
    captureException(
      `Error fetching today: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching today: " + error);
  }
};

export const fetchYesterday = () => (dispatch) => {
  try {
    dispatch(setLoadingYesterday(true));

    const currentUser = auth.currentUser;
    if (!currentUser) {
      dispatch(setLoadingYesterday(false));
      captureException("User not authenticated when fetching yesterday");
      return console.log("User not authenticated when fetching yesterday");
    }

    const userUid = currentUser.uid;

    dispatch(fetchYesterdaySuccess(null));

    const yesterdaySubscriber = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(moment().subtract(1, "days").format("YYYYMMDD"))
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();

            const yesterday = {
              ...data,
              ...(data?.date && {
                date: moment(
                  data.date && data.date.toDate && data.date.toDate()
                )
                  .utc()
                  .utcOffset(moment().utcOffset(), true),
              }),
            };
            dispatch(fetchYesterdaySuccess(yesterday));
          }
          dispatch(setLoadingYesterday(false));
        },
        (error) => {
          dispatch(setLoadingYesterday(false));
          captureException(
            `Error fetching yesterday onSnapshot: ${
              error.message || "Unknown error"
            }`,
            error
          );
          console.log("Error fetching yesterday onSnapshot: " + error);
        }
      );
    unsubscribers.push(yesterdaySubscriber);
  } catch (error) {
    dispatch(setLoadingYesterday(false));
    captureException(
      `Error fetching yesterday: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching yesterday: " + error);
  }
};

// This is used to close onSnapshots every time the date is changed
export const unsubscribeDays = () => {
  unsubscribersDay.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribersDay = [];
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeDayActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
