import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { debounce } from "lodash";

// Components
import { FiTrash2, FiCalendar, FiCopy } from "react-icons/fi";
import { MdOutlinePalette } from "react-icons/md";
import {
  useTheme,
  IconButton,
  TextField,
  Checkbox,
  Divider,
  Tooltip,
} from "@mui/material";

import MonthPickerOverlay from "../../../components/MonthPickerOverlay";
import TagPickerOverlay from "../../../components/TagPickerOverlay";
import ScreenHeader from "../../../components/ScreenHeader";

// Firebase
import {
  addTarget,
  updateTarget,
  deleteTarget,
} from "../../../api/firestore/targets";

// Analytics
import { analytics } from "../../../api/firebase";

// Own api
import { tagColors, Devices } from "../../../api/constants";
import "./style.scss";

const EditTargetScreen = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const [target, setTarget] = useState({
    ...location.state.goalToEdit,
    ...(location.state.goalToEdit.date && {
      date: moment(location.state.goalToEdit.date)
        .utc()
        .utcOffset(moment().utcOffset(), true),
    }), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
    ...(location.state.goalToEdit.createdAt && {
      createdAt: moment(location.state.goalToEdit.createdAt),
    }), // is received as "date" and converted back into moment
    ...(location.state.goalToEdit.updatedAt && {
      updatedAt: moment(location.state.goalToEdit.updatedAt),
    }), // is received as "date" and converted back into moment
  });
  const [date, setDateChange] = useState(moment(location.state.date)); // date is received as "date" and converted to moment obj
  const [tag, setTagChange] = useState(target.tag);
  const [name, setNameChange] = useState(target.name);
  const [description, setDescriptionChange] = useState(target.description);

  const [isTagPickerOverlayVisible, setIsTagPickerOverlayVisible] =
    useState(false);
  const [isDateTimePickerOverlayVisible, setIsDateTimePickerOverlayVisible] =
    useState(false);

  const handleUpdate = (item) => {
    if (!item.newDate) {
      updateTarget(item);
      setTarget(item);
    } else {
      delete item.newDate; // Remove newDate from it (not necessary for target)
      updateTarget(item);
      navigate("/targets", {
        state: {
          date: date.toDate(),
        },
      });
      setTimeout(() => {
        toast.success(`Moved to ${moment(item.date).format("MMMM")}!`);
      }, 1);
    }
  };

  const handleDelete = (item) => {
    deleteTarget(item);
    navigate("/targets", {
      state: {
        date: date.toDate(),
        goal: {
          ...item,
          createdAt:
            item.createdAt && item.createdAt.toDate && item.createdAt.toDate(),
          date: date?.toDate && date?.toDate(),
          updatedAt: new Date(),
        },
      },
    });
  };

  const handleDuplicate = async (item) => {
    let duplicatedTargetId = await addTarget({
      name: (item.name || "(No title)") + " COPY",
      isDone: false,
      tag: item.tag,
      description: item.description,
      date: item.date,
    });

    analytics.logEvent("used_duplicate_btn");

    navigate("/targets", {
      state: {
        date: date.toDate(),
        duplicatedTargetId: duplicatedTargetId,
      },
    });
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateNameOrDescription = useCallback(
    debounce((property, value) => {
      handleUpdate({
        ...target,
        [property]: value,
      });
    }, 300),
    [target]
  );

  const btnBackClicked = () => {
    navigate("/targets", {
      state: {
        date: date.toDate(),
      },
    });
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/targets", {
          state: {
            date: date.toDate(),
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="edit-target-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice !== Devices.PHONE && "100vh",
        overflowY: currentDevice !== "PHONE" && "scroll",
      }}
    >
      <div className="edit-target-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Targets"
          title="Edit Target"
        />

        <Divider />

        <div className="body">
          <div className="edit-btns">
            <div className="edit-btns-first-section">
              <Tooltip title="Month">
                <button
                  className="btn-edit"
                  onClick={() =>
                    !target.isDone
                      ? setIsDateTimePickerOverlayVisible(true)
                      : toast.error(
                          "You can't change the month of already achieved targets"
                        )
                  }
                >
                  <FiCalendar style={{ color: theme.palette.gray.dark }} />
                  <label
                    className="btn-edit-date"
                    style={{ color: theme.palette.gray.dark }}
                  >
                    {date
                      ? moment(date).year() === moment().year()
                        ? moment(date).format("MMMM")
                        : moment(date).format("MMMM, Y")
                      : "No month"}
                  </label>
                </button>
              </Tooltip>

              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />

              <Tooltip title="Color">
                <IconButton
                  className="btn-edit"
                  onClick={() => setIsTagPickerOverlayVisible(true)}
                >
                  <MdOutlinePalette
                    style={{
                      color: !target.tag
                        ? theme.palette.gray.dark
                        : tagColors[target.tag - 1].color,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>

            <div className="edit-btns-first-section">
              <Tooltip title="Duplicate">
                <IconButton
                  className="btn-edit"
                  onClick={() => handleDuplicate(target)}
                >
                  <FiCopy style={{ color: theme.palette.gray.dark }} />
                </IconButton>
              </Tooltip>
              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />
              <Tooltip title="Delete">
                <IconButton
                  className="btn-edit"
                  onClick={() => handleDelete(target)}
                >
                  <FiTrash2 style={{ color: theme.palette.gray.dark }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Divider />

          <div className="roll-over-container">
            <Checkbox
              color="primary"
              checked={
                target.isRolledOver !== undefined ? target.isRolledOver : true
              }
              onChange={() =>
                handleUpdate({ ...target, isRolledOver: !target.isRolledOver })
              }
            />
            <label style={{ color: theme.palette.gray.dark }}>
              Roll over until complete
            </label>
          </div>

          <Divider />

          <div className="title-container">
            {tag ? (
              <div
                className="target-tag"
                style={{
                  backgroundColor: tag
                    ? tagColors[tag - 1].color
                    : "transparent",
                }}
              />
            ) : null}

            <TextField
              type="text"
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true,
                  style: {
                    display: "flex",
                    color: theme.palette.text.main,
                    fontSize: 20,
                    fontWeight: "bold",
                  },
                },
                htmlInput: {
                  maxLength: 140,
                },
              }}
              fullWidth
              autoComplete="off"
              multiline
              id="goalNameInput"
              placeholder="Add target name..."
              value={name}
              onChange={(e) => {
                setNameChange(e.target.value);
                updateNameOrDescription("name", e.target.value);
              }}
              className="goal-name"
              style={{
                paddingLeft: tag ? 10 : 0,
                color: theme.palette.text.main,
              }}
              required
            />
          </div>

          <Divider />

          <div className="goal-description-section">
            <label
              className="goal-description-title"
              style={{ color: theme.palette.text.main }}
            >
              Target description
            </label>
            <TextField
              type="text"
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true,
                  style: { color: theme.palette.text.main },
                },
              }}
              sx={{ paddingTop: 1.5 }}
              fullWidth
              autoComplete="off"
              multiline
              minRows={10}
              id="goalDescriptionInput"
              placeholder="When, where and how will you get this done - and any reward once completed?"
              value={description}
              onChange={(e) => {
                setDescriptionChange(e.target.value);
                updateNameOrDescription("description", e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* Overlays */}
      <TagPickerOverlay
        isVisible={isTagPickerOverlayVisible}
        setIsVisible={setIsTagPickerOverlayVisible}
        tag={tag}
        setTagChange={setTagChange}
        goal={target}
        handleUpdate={handleUpdate}
      />
      <MonthPickerOverlay
        isVisible={isDateTimePickerOverlayVisible}
        setIsVisible={setIsDateTimePickerOverlayVisible}
        date={moment(date)}
        setDateChange={setDateChange}
        goal={target}
        handleUpdate={handleUpdate}
        minDate={moment(props.minDate).startOf("month")}
        showRemoveDate
      />
    </div>
  );
};

export default React.memo(EditTargetScreen);
