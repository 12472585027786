import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { captureException } from "@sentry/react";

import { CircularProgress, useTheme } from "@mui/material";

import { auth } from "../../api/firebase";

import { store } from "../../redux/store";

import SentryService from "../../utils/sentry";
import { unsubscribeAllSnapshots } from "../../api/utils/snapshots";
import "./style.scss";

function SignOutButton() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [loadingSignOut, setLoadingSignOut] = useState(false);

  const logOut = async () => {
    setLoadingSignOut(true);
    try {
      // await removeToken(); // TODO - Notifications

      // Check if providerData exists and is an array
      const providers = auth.currentUser?.providerData || [];
      if (
        Array.isArray(providers) &&
        providers.some((el) => el.providerId === "google.com")
      ) {
        googleLogout();
      }

      // Clear onSnapshot listeners now to prevent them from checking after they no longer have access (no auth())
      unsubscribeAllSnapshots();

      // Sign out the user
      await auth.signOut();

      // Clear Sentry User
      SentryService.clearSentryUser();

      // Reset store
      store.dispatch({ type: "SIGNOUT_REQUEST" });

      navigate("/");

      setLoadingSignOut(false);
    } catch (error) {
      setLoadingSignOut(false);
      captureException("Error on sign out", error);
      console.error("Error on sign out: " + error);
    }
  };

  return (
    <li
      style={{
        cursor: "pointer",
        borderTop: "1px solid",
        borderTopColor: theme.palette.gray.contrast,
        borderBottom: "none",
      }}
      onClick={logOut}
    >
      {loadingSignOut ? (
        <CircularProgress color="error" size={25} />
      ) : (
        <label style={{ cursor: "pointer", color: theme.palette.error.main }}>
          Sign out
        </label>
      )}
    </li>
  );
}

export default React.memo(SignOutButton);
