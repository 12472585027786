import React, { useState, useEffect } from "react";

// ---- Redux ----
import { Provider, useSelector, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

// ---- Sentry ----
import * as Sentry from "@sentry/react";

// ---- Auth ----
import { auth } from "./api/firebase";

// ---- Analytics ----
import { analytics } from "./api/firebase";

// ---- Google login ----
import { GoogleOAuthProvider } from "@react-oauth/google";

// ---- Theming ----
import { ThemeProvider } from "@mui/material";
import { lightTheme, darkTheme } from "./api/theme";

// ---- Router ----
import { BrowserRouter, Routes, Route } from "react-router-dom";

// ---- History ----
import { createBrowserHistory } from "history";

// ---- Screens ----
import MainScreen from "./screens/MainScreen";
import SignUpScreen from "./screens/auth";
import SignInScreen from "./screens/auth/signIn";
import ResetPasswordScreen from "./screens/auth/resetPassword";
import TutorialNavigation from "./screens/tutorial";
import ReleasesScreen from "./screens/releases";

// ---- Redux ----
import { fetchReleases } from "./redux/actions/release";
import { fetchQuiz } from "./redux/actions/quiz";
import { fetchTags } from "./redux/actions/tags";
import { fetchUser } from "./redux/actions/user";
import { setCurrentDevice } from "./redux/actions/local";

// ---- Guide ----
import { ShepherdTour } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";

// ---- Components ----
import LoadingScreen from "./components/LoadingScreen";

// ---- Own api ----
import { getDeviceSize, hasOnlyIdField } from "./api/helper";
import { googleSignInWebClientId } from "./api/credentials";
import { guideSteps } from "./api/constants";
import SentryService from "./utils/sentry";

import "./style.scss";

function AppWrapper() {
  const tourOptions = {
    defaultStepOptions: {
      classes: "shepherd-theme-custom",
      cancelIcon: {
        enabled: true,
      },
    },
    useModalOverlay: true,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={googleSignInWebClientId}>
          <ShepherdTour steps={guideSteps} tourOptions={tourOptions}>
            <App />
          </ShepherdTour>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default Sentry.withProfiler(AppWrapper);

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

function App() {
  const history = createBrowserHistory();
  const dispatch = useDispatch();

  // Redux
  const isDarkModeEnabled = useSelector(
    (state) => state.local.isDarkModeEnabled
  );
  const user = useSelector((state) => state.user.user);
  const lastSeenRelease = useSelector((state) => state.local.lastSeenRelease);
  const releases = useSelector((state) => state.release.releases);
  const isLoadingUser = useSelector((state) => state.user.isLoading);
  // const isLoadingReleases = useSelector((state) => state.release.isLoading);
  const isLoadingTags = useSelector((state) => state.tags.isLoading);

  // Theme
  var selectedTheme = isDarkModeEnabled ? darkTheme : lightTheme;

  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [authUser, setAuthUser] = useState(null);
  const [isUserSetupComplete, setIsUserSetupComplete] = useState(false); // Used to prevent sending client to tutorial before coach is assigned and everything

  // Everything inside this useEffect runs when the client has authenticated successfully. So... once after login/sign up
  const onAuthStateChanged = (loggedUser) => {
    setAuthUser(loggedUser);
    if (initializing) {
      setInitializing(false);
    }
    // Once the user is set, fetch things
    if (loggedUser) {
      dispatch(fetchUser());

      // Attach client uid to all analytics events
      analytics.setUserId(loggedUser.uid);

      // Set Sentry user
      SentryService.setSentryUser(loggedUser);
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authUser && user?.id) {
      dispatch(fetchTags());
    }
  }, [authUser?.uid, user?.id]);

  useEffect(() => {
    if (authUser && user?.id && user?.email && user?.coach) {
      dispatch(fetchQuiz(user?.email));
    }
  }, [authUser?.uid, user?.id, user?.email, user?.coach]); // Everything inside this useEffect runs when the client has authenticated successfully and its user doc has been retrieved. // It won't run on first load or after signout as user id will be undefined

  useEffect(() => {
    // user.id and user.coach are mandatory
    if (authUser && user?.id && user?.coach) {
      if (!isUserSetupComplete) {
        setIsUserSetupComplete(true);
      }
      // Attach every relevant property for all user's analytics events (they are limited)
      analytics.setUserProperties({
        type: user?.type || "",
        coach: user.coach,
      });
    }
  }, [authUser, user?.id, user?.type, user?.coach]);

  useEffect(() => {
    if (authUser && user?.id) {
      // ATTENTION - The way it is now, release notes screen doesn't prevent entering Main.js and running code there!
      dispatch(fetchReleases(lastSeenRelease));
    }
  }, [authUser, user?.id, lastSeenRelease]); // Everything inside this useEffect runs when the client has authenticated successfully and its user doc has been retrieved. // It won't run on first load or after signout as user id will be undefined

  // Current device
  useEffect(() => {
    dispatch(setCurrentDevice(getDeviceSize()));
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <ThemeProvider theme={selectedTheme}>
        {initializing || isLoadingUser || isLoadingTags ? (
          <LoadingScreen />
        ) : (
          <BrowserRouter history={history}>
            {!authUser || !user || hasOnlyIdField(user) ? ( // As the authentication runs before the whole sign up process, where the user is added, this conditional prevents from entering when at the beginning we only have user = {id: XXXXXX}. In summary, it waits for the user's fields to be added
              <Routes>
                <Route
                  path="/ResetPassword"
                  element={<ResetPasswordScreen />}
                />
                <Route path="/SignIn" element={<SignInScreen />} />
                <Route path="/SignUp" element={<SignUpScreen />} />
                <Route path="/" element={<SignUpScreen />} />
              </Routes>
            ) : !isUserSetupComplete ? (
              <LoadingScreen />
            ) : releases?.length ? (
              <ReleasesScreen />
            ) : !user?.finishedTutorial ? (
              <TutorialNavigation />
            ) : (
              <>
                {/* {console.log("re-render")} */}
                <MainScreen />
              </>
            )}
          </BrowserRouter>
        )}
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}
