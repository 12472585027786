import { firestore, auth } from "../../api/firebase";
import { FETCH_ALERTS, SET_LOADING_ALERTS } from "../types";
import moment from "moment";

import { captureException } from "@sentry/react";

const fetchAlertSuccess = (alerts) => ({
  type: FETCH_ALERTS,
  payload: alerts,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_ALERTS,
  payload: isLoading,
});

let unsubscribers = [];

export const fetchAlerts = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const currentUser = auth.currentUser;
    if (!currentUser) {
      dispatch(setLoading(false));
      captureException("User not authenticated when fetching alerts");
      return console.log("User not authenticated when fetching alerts");
    }

    const userUid = currentUser.uid;

    // Get enabled alerts
    const alertsSubscriber = firestore
      .collection("alerts")
      .where("isEnabled", "==", true)
      .orderBy("date", "desc")
      .onSnapshot((alertsSnapshot) => {
        const enabledAlerts = alertsSnapshot?.docs?.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id, // References the UID
          };
        });

        if (enabledAlerts?.length) {
          // Get alerts seen by client - Using a snapshot here to listen if client updates clientAlert and so filter the alerts again
          const seenAlertsSubscriber = firestore
            .collection("clientAlert")
            .doc(userUid)
            .collection("alerts")
            .onSnapshot((seenAlertsSnapshot) => {
              const seenAlerts = seenAlertsSnapshot?.docs?.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id, // References the UID
                };
              });

              // Filter alerts to keep
              const alerts =
                enabledAlerts?.length &&
                enabledAlerts.filter((enabledAlert) => {
                  const correspondingClientAlert =
                    seenAlerts?.length &&
                    seenAlerts.find((seenAlert) => {
                      return seenAlert.id === enabledAlert.id;
                    });

                  return (
                    // Keep if:
                    // - hasn't been seen yet
                    // or
                    // - It's been seen but before the new date of the alert
                    !correspondingClientAlert?.seenAt ||
                    moment(
                      correspondingClientAlert?.seenAt?.toDate &&
                        correspondingClientAlert?.seenAt?.toDate()
                    ).isBefore(
                      moment(
                        enabledAlert?.date?.toDate && enabledAlert.date.toDate()
                      )
                    )
                  );
                });

              dispatch(fetchAlertSuccess(alerts));
            });

          unsubscribers.push(seenAlertsSubscriber);
        }
      });

    unsubscribers.push(alertsSubscriber);

    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    captureException(
      `Error fetching alerts: ${error.message || "Unknown error"}`,
      error
    );
    console.log("Error fetching alerts: ", error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeAlertActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
